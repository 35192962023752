<template>
  <div class=WordSection1>

<p class=MsoNormal style='text-align:left; margin-bottom: 20.0pt'><span class=SpellE><span lang=EN style='font-size:30.0pt;
font-family:"ParmaPetit",serif;mso-fareast-font-family:"ParmaPetit";color:#085DA3;'>Disclaimer</span></span></p>

<p class=MsoNormal style=''><span>We are not affiliated, associated, authorized or endorsed by Roxe Inc or any of its affiliates (“Roxe”). Any references herein to Roxe or its products and services in no way indicates any relationship or affiliation between us and Roxe, nor does it signify that Roxe is the source of, endorses, approves of or sponsors in any way the content published herein. The ideas, views and opinions expressed herein do not express the ideas, views or opinions of Roxe.</span></p>


</div>

</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.u-header__section {
  background: #000;
}
.WordSection1 {
  padding: 160px 50px 80px;
  background: #fff;
  min-height: calc(100vh - 240px);
}
.MsoNormal {
  font-family: 'Louis George Cafe';
  font-size: 16px;
  line-height: 24px;
  color: #818E9E;
  background: #fff;
  margin-bottom: 14.0pt;
}
@media screen and (max-width: 991px) {
  .WordSection1 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .WordSection1 {
    padding: 70px 20px 80px;
  }
}
</style>
